import { RotatingSquare } from "react-loader-spinner";

const Preloader = () => {
  return (
    <div className="preloader">
      <div className="d-flex align-items-center justify-content-center w-100 h-100">
        <RotatingSquare
          height="100"
          width="100"
          color="#A91F22"
          ariaLabel="rotating-square-loading"
          strokeWidth="4"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    </div>
  );
};

export default Preloader;
