import React, { useState, useEffect, useRef } from "react";
import TitleIcon from "./TitleIcon";
import Detecation from "../assets/image/icons/dedication.svg";
import Smart from "../assets/image/icons/smart.svg";
import Idea from "../assets/image/icons/idea.svg";
import Cubes from "../assets/image/icons/cubes.svg";
import GrayCubes from "../assets/image/icons/union.svg";
import Ellipse from "../assets/image/icons/about-ellipse.svg";
import Globe from "../assets/image/icons/globe.svg";
import Pin from "../assets/image/icons/pin.svg";
import Feedback from "../assets/image/icons/feedback.svg";
import AboutImg from "../assets/image/about-image.png";
const ICON = require("../assets/image/icons/wired-outline-1948-aztec-calendar.json");

const About = () => {
  const playerRef = useRef(null);

  return (
    <section id="about" className="about-section py-5 my-5">
      <div className="position-relative pb-2 mb-4">
        <img src={Cubes} className="cubes" />
        <div className="container">
          <div className="row align-items-center mb-5">
            <div className="col-md-4">
              <TitleIcon
                icon={Detecation}
                title="Dedication"
                description="Sed do eiusmod tempor incididunt ut labore ullamco laboris nisi ut aliquip ex ea commo do conse namber onequat."
              />
            </div>
            <div className="col-md-4">
              <TitleIcon
                icon={Smart}
                title="Smart Work"
                description="Sed do eiusmod tempor incididunt ut labore ullamco laboris nisi ut aliquip ex ea commo do conse namber onequat."
              />
            </div>
            <div className="col-md-4">
              <TitleIcon
                icon={Idea}
                title="Intelligence"
                description="Sed do eiusmod tempor incididunt ut labore ullamco laboris nisi ut aliquip ex ea commo do conse namber onequat."
              />
            </div>
          </div>
        </div>
      </div>
      <div id="about-bg" className="bg-main-black position-relative h-100 pt-5">
        <img src={GrayCubes} className="gray-cubes" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="position-relative">
                <div className="about-image-wrap position-relative">
                  <img src={AboutImg} className="about-image w-100" />
                  <img
                    src={Ellipse}
                    className="position-absolute w-100 ellipse"
                  />
                </div>
                <img src={Globe} className="about-icon about-icon-globe" />
                <img src={Pin} className="about-icon about-icon-pin" />
                <img
                  src={Feedback}
                  className="about-icon about-icon-feedback"
                />
              </div>
            </div>
            <div className="col-md-6">
              <h3 className="about-title text-white mb-3 py-3 rec-red position-relative">
                About me
              </h3>
              <p className="description text-white">
                Lorem ipsum dolor sit amet consectetur. Sagittis non at volutpat
                egestas. Nulla enim ut id lacus sociis. In donec vitae ultrices
                sodales nunc morbi eros. Non pretium lorem consectetur vitae
                feugiat. Natoque aliquam volutpat lacus scelerisque gravida
                libero condimentum. Aliquet malesuada eu risus sed pretium
                massa. Aliquam malesuada massa diam laoreet. Risus tincidunt
                nunc sed maecenas nulla adipiscing tortor id vitae. Feugiat
                viverra duis a eu amet cursus morbi. Ligula ornare convallis in
                vel massa vel erat. Quam quis enim adipiscing at facilisi.
                Massa.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
