import React, { useState, useEffect } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Project1 from "../assets/image/project-1.png";
import Project2 from "../assets/image/project-2.png";
import DateImg from "../assets/image/icons/date.svg";

const Work = () => {
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [showNavigation, setShowNavigation] = useState(true);

  const slides = [
    {
      src: Project1,
      title: "Kitten 1",
      date: "15-07-2024",
      description:
        "Sed do eiusmod tempor incididunt ut labore ullamco laboris nisi ut aliquip ex ea commo do conse namber onequat.",
    },
    {
      src: Project2,
      title: "Kitten 2",
      date: "15-07-2024",
      description:
        "Sed do eiusmod tempor incididunt ut labore ullamco laboris nisi ut aliquip ex ea commo do conse namber onequat.",
    },
    {
      src: Project1,
      title: "Kitten 2",
      date: "15-07-2024",
      description:
        "Sed do eiusmod tempor incididunt ut labore ullamco laboris nisi ut aliquip ex ea commo do conse namber onequat.",
    },
    {
      src: Project2,
      title: "Kitten 2",
      date: "15-07-2024",
      description:
        "Sed do eiusmod tempor incididunt ut labore ullamco laboris nisi ut aliquip ex ea commo do conse namber onequat.",
    },
  ];

  const handleSlideChange = (swiper) => {
    setIsLastSlide(swiper.isEnd);
  };

  useEffect(() => {
    setIsLastSlide(false);
  }, []);

  useEffect(() => {
    function checkArrow() {
      const width = window.innerWidth;
      setShowNavigation(width >= 640);
    }
    checkArrow();

    window.addEventListener("resize", checkArrow);
    return () => {
      window.removeEventListener("resize", checkArrow);
    };
  }, []);

  function checkArrow() {
    var swiperPrev = document.querySelector(".image-swiper-button-prev");
    var swiperNext = document.querySelector(".image-swiper-button-next");
    if (window.innerWidth < 1024) {
      swiperPrev.style.display = "block";
      swiperNext.style.display = "block";
    } else {
      swiperPrev.style.display = "none";
      swiperNext.style.display = "none";
    }
  }

  return (
    <section className="py-5 my-5">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center position-relative">
            <h3 className="work-title mb-5 py-3 rec-red position-relative">
              My work
            </h3>
            {showNavigation && (
              <>
                <div className="swiper-button image-swiper-button-next">
                  <svg
                    width="30"
                    height="20"
                    viewBox="0 0 30 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M20.9289 18.839L28.8839 10.884C29.372 10.3959 29.372 9.6044 28.8839 9.11624L20.9289 1.16129C20.4408 0.673138 19.6493 0.673138 19.1612 1.16129C18.673 1.64945 18.673 2.4409 19.1612 2.92906L24.9822 8.75013L2 8.75013C1.30964 8.75013 0.75 9.30977 0.75 10.0001C0.75 10.6905 1.30964 11.2501 2 11.2501L24.9822 11.2501L19.1612 17.0712C18.673 17.5594 18.673 18.3508 19.1612 18.839C19.6493 19.3271 20.4408 19.3271 20.9289 18.839Z"
                      fill="#001715"
                    />
                  </svg>
                </div>
                <div className="swiper-button image-swiper-button-prev">
                  <svg
                    width="29"
                    height="19"
                    viewBox="0 0 29 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.32107 18.0436L0.366116 10.0886C-0.122038 9.60044 -0.122038 8.80899 0.366116 8.32083L8.32107 0.365883C8.80922 -0.122272 9.60068 -0.122272 10.0888 0.365883C10.577 0.854038 10.577 1.64549 10.0888 2.13365L4.26777 7.95472L27.25 7.95472C27.9404 7.95472 28.5 8.51436 28.5 9.20472C28.5 9.89507 27.9404 10.4547 27.25 10.4547L4.26777 10.4547L10.0888 16.2758C10.577 16.7639 10.577 17.5554 10.0888 18.0436C9.60068 18.5317 8.80922 18.5317 8.32107 18.0436Z"
                      fill="#001715"
                    />
                  </svg>
                </div>
              </>
            )}
            <Swiper
              spaceBetween={20}
              slidesPerView={2}
              modules={[Navigation]}
              navigation={{
                nextEl: ".image-swiper-button-next",
                prevEl: ".image-swiper-button-prev",
                disabledClass: "swiper-button-disabled",
                clickable: true,
              }}
              on={{
                init: function () {
                  checkArrow();
                },
                resize: function () {
                  checkArrow();
                },
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                  navigation: {
                    enabled: false,
                  },
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
              }}
              loop={true}
              pagination={{ clickable: true }}
              onSlideChange={handleSlideChange}
              onSwiper={(swiper) => console.log(swiper)}
              effect="fade"
            >
              {slides.map((slide, index) => (
                <SwiperSlide className="swipe-item" key={index}>
                  <img src={slide.src} alt={slide.title} />
                  <div className="swiper-slide-content">
                    <h3 className="pt-4">{slide.title}</h3>
                    <p className="description">{slide.description}</p>
                    <div className="date">
                      <img src={DateImg} alt="date" className="me-1" />
                      <span> {slide.date}</span>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Work;
