import { Suspense, lazy, useEffect, useState } from "react";
import "./App.scss";
import Preloader from "./components/Preloader";
import About from "./components/About";
import Work from "./components/Work";
import ScrollIndicator from "./components/ScrollIndicator";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

const Header = lazy(() => import("./components/Header"));
const Home = lazy(() => import("./components/Home"));

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <>
      <div className="App">
        {/* {loading ? (
          <Preloader />
        ) : (
         */}
        <Suspense fallback={<Preloader />}>
          <Header />
          <Home />
          <About />
          <Work />
          <Contact />
          <Footer />
          <ScrollIndicator />
        </Suspense>
        {/* )} */}
      </div>
    </>
  );
}

export default App;
