import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import GrayCubes from "../assets/image/icons/union.svg";

const schema = yup.object().shape({
  fullName: yup.string().required("Full name is required"),
  emailAddress: yup
    .string()
    .email("Invalid email")
    .required("Email is required"),
  phoneNumber: yup.string().required("Phone number is required"),
  message: yup.string().required("Message is required"),
});

const Contact = () => {
  const [visiable, setVisiable] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setVisiable(true);
    reset({emailAddress: '', phoneNumber: '', fullName: '', message: ''});
  };

  return (
    <section className="contact-section position-relative py-5 my-5">
      <img src={GrayCubes} className="gray-cubes" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3 className="contact-title position-relative mb-4 py-3  rec-red">
              Contact me
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p className="description">
              Sed do eiusmod tempor incididunt ut labore ullamco laboris nisi ut
              aliquip ex ea commo do conse namber onequat.
            </p>
            <ul className="mt-5 list-unstyled">
              <li className="mb-5">
                <h5>Address</h5>
                <div className="d-flex align-items-center">
                  <svg
                    width="11"
                    height="16"
                    viewBox="0 0 11 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 5.48783C0 2.45384 2.45929 0 5.5 0C8.54071 0 11 2.45384 11 5.48783C11 8.75701 7.52714 13.2649 6.105 14.9818C5.79071 15.3581 5.21714 15.3581 4.90286 14.9818C3.47286 13.2649 0 8.75701 0 5.48783ZM3.53571 5.48788C3.53571 6.56977 4.41571 7.44782 5.5 7.44782C6.58429 7.44782 7.46429 6.56977 7.46429 5.48788C7.46429 4.406 6.58429 3.52795 5.5 3.52795C4.41571 3.52795 3.53571 4.406 3.53571 5.48788Z"
                      fill="#001715"
                    />
                  </svg>

                  <span className="ms-2">20, Somewhere in world</span>
                </div>
              </li>
              <li className="mb-5">
                <h5>Email</h5>
                <div className="d-flex align-items-center">
                  <svg
                    width="17"
                    height="12"
                    viewBox="0 0 17 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.0469 5.81536L16.6656 9.36744V2.11328L11.0469 5.81536Z"
                      fill="#001715"
                    />
                    <path
                      d="M0 2.11328V9.36744L5.61875 5.81536L0 2.11328Z"
                      fill="#001715"
                    />
                    <path
                      d="M15.625 0H1.04167C0.521875 0 0.109375 0.3875 0.03125 0.886458L8.33333 6.35625L16.6354 0.886458C16.5573 0.3875 16.1448 0 15.625 0Z"
                      fill="#001715"
                    />
                    <path
                      d="M10.0917 6.44245L8.61771 7.41328C8.53021 7.47057 8.43125 7.4987 8.33125 7.4987C8.23125 7.4987 8.13229 7.47057 8.04479 7.41328L6.57083 6.44141L0.03125 10.5779C0.111458 11.0727 0.521875 11.457 1.03958 11.457H15.6229C16.1406 11.457 16.551 11.0727 16.6313 10.5779L10.0917 6.44245Z"
                      fill="#001715"
                    />
                  </svg>

                  <span className="ms-2">Hello@gmail.com</span>
                </div>
              </li>
              <li className="mb-5">
                <h5>Phone</h5>
                <div className="d-flex align-items-center">
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.7964 9.84578C12.878 9.84578 11.9763 9.70215 11.1218 9.41977C10.7031 9.27695 10.1884 9.40797 9.93283 9.67043L8.24623 10.9436C6.29026 9.89953 5.08542 8.69508 4.05557 6.75379L5.29131 5.11113C5.61237 4.79051 5.72752 4.32215 5.58956 3.8827C5.30596 3.02371 5.1619 2.12242 5.1619 1.20367C5.16194 0.539961 4.62198 0 3.95831 0H1.20363C0.539959 0 0 0.539961 0 1.20363C0 8.81105 6.18897 15 13.7964 15C14.46 15 15 14.46 15 13.7964V11.0494C15 10.3857 14.46 9.84578 13.7964 9.84578Z"
                      fill="#001715"
                    />
                  </svg>
                  <span className="ms-2">+123 456 7890</span>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="form-wrap">
              <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-item">
                  <label>Full name</label>
                  <Controller
                    name="fullName"
                    control={control}
                    render={({ field }) => (
                      <input {...field} className="form-control" />
                    )}
                  />
                  <p className="error-message">{errors.fullName?.message}</p>
                </div>
                <div className="form-item">
                  <label>Email address</label>
                  <Controller
                    name="emailAddress"
                    control={control}
                    render={({ field }) => (
                      <input {...field} type="email" className="form-control" />
                    )}
                  />
                  <p className="error-message">
                    {errors.emailAddress?.message}
                  </p>
                </div>
                <div className="form-item">
                  <label>Phone number</label>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field }) => (
                      <input
                        type="number"
                        {...field}
                        className="form-control"
                      />
                    )}
                  />
                  <p className="error-message">{errors.phoneNumber?.message}</p>
                </div>
                <div className="form-item">
                  <label>Message</label>
                  <Controller
                    name="message"
                    control={control}
                    render={({ field }) => (
                      <textarea {...field} className="form-control" />
                    )}
                  />
                  <p className="error-message">{errors.message?.message}</p>
                </div>
                <button className="btn send-btn" type="submit">
                  Send message
                </button>
                {visiable && (
                  <p className="text-success text-small mt-2">
                    Your message has been sent successfully.
                  </p>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
