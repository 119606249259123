const TitleIcon = (params) => {
  return (
    <div className="title-icon mb-5">
      <div className="d-flex align-items-center flex-row mb-3">
        <img src={params.icon} />
        <h4 className="red-title-icon mb-0">{params.title}</h4>
      </div>

      <p className="description">{params.description}</p>
    </div>
  );
};

export default TitleIcon;
