import React, { useState, useEffect } from "react";

const Footer = () => {
  return (
    <footer className="py-3 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <ul className="list-unstyled mb-sm-0 mb-3 justify-content-sm-start justify-content-center d-flex align-items-center mb-0">
              <li className="me-4">
                <a href="#">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_518_1979)">
                      <path
                        d="M15.9429 3.5038C15.8839 3.45028 15.7961 3.44361 15.7297 3.48763C15.436 3.68242 15.0403 3.78131 14.7113 3.83154C15.1046 3.51071 15.5634 3.00644 15.5603 2.35662C15.56 2.29126 15.5231 2.23152 15.4648 2.20203C15.4064 2.17253 15.3365 2.17825 15.2836 2.21675C14.6875 2.65179 13.833 2.8803 13.5201 2.95327C13.2989 2.58867 12.5379 1.51953 11.2227 1.51953C11.1492 1.51953 11.0741 1.52301 10.9996 1.52983C9.3652 1.68015 8.57322 2.63592 8.19668 3.41128C7.95862 3.90148 7.85407 4.37611 7.80826 4.68958C7.54906 4.46365 7.09696 4.11984 6.42401 3.77578C5.39597 3.25007 3.63026 2.62343 1.05686 2.62343C0.806658 2.62343 0.549647 2.62935 0.292933 2.64104C0.234537 2.64373 0.181364 2.67551 0.15137 2.7257C0.121376 2.77589 0.118591 2.83782 0.144008 2.89049C0.693249 4.03116 1.87475 4.70117 2.64389 5.03294C2.22189 5.14476 1.77099 5.36292 1.62977 5.70663C1.5581 5.88112 1.53969 6.15535 1.85898 6.47085C2.32774 6.93404 2.87882 7.21548 3.36439 7.38634C2.98397 7.45403 2.6802 7.57023 2.58172 7.76357C2.54426 7.83719 2.50153 7.9883 2.63643 8.17374C3.28312 9.06301 4.8331 9.40881 5.52042 9.46521C5.43322 9.67239 5.25162 10.0088 4.90089 10.3419C4.15149 11.0537 2.96985 11.4299 1.48368 11.43C1.48353 11.43 1.48339 11.43 1.48324 11.43C1.07939 11.43 0.645945 11.4026 0.194794 11.3486C0.120282 11.3398 0.0483564 11.3795 0.0164724 11.4476C-0.0154116 11.5156 -9.13217e-05 11.5964 0.0544746 11.648C2.01372 13.5009 4.23794 14.4804 6.48674 14.4808C6.48718 14.4808 6.48748 14.4808 6.48788 14.4808C8.96359 14.4808 11.3876 13.2635 12.972 11.2246C14.383 9.40876 14.9235 7.23537 14.4672 5.23658C14.772 5.10611 15.4448 4.72032 15.9796 3.7144C16.017 3.64407 16.0019 3.55732 15.9429 3.5038Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_518_1979">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </li>
              <li className="me-4">
                <a href="#">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.3547 0H10.4889C8.08224 0 6.12434 1.95796 6.12434 4.36458V6.20657H4.645C4.49267 6.20657 4.36914 6.3301 4.36914 6.48243V8.68932C4.36914 8.84166 4.49267 8.96519 4.645 8.96519H6.12423V15.7241C6.12423 15.8765 6.24776 16 6.40009 16H8.60699C8.75932 16 8.88285 15.8765 8.88285 15.7241V8.96519H11.0779C11.2303 8.96519 11.3538 8.84166 11.3538 8.68932V6.48243C11.3538 6.3301 11.2303 6.20657 11.0779 6.20657H8.88285V4.36458C8.88285 3.47906 9.60329 2.75862 10.4888 2.75862H11.3546C11.507 2.75862 11.6305 2.63509 11.6305 2.48276V0.275862C11.6306 0.123531 11.5071 0 11.3547 0Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </li>
              <li className="me-4">
                <a href="#">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_518_1983)">
                      <path
                        d="M2.13257 0.0112305C0.957544 0.0112305 0.000860327 0.96773 0 2.14367C0 3.31962 0.956684 4.2763 2.13257 4.2763C3.30802 4.2763 4.26421 3.31962 4.26421 2.14367C4.26421 0.967853 3.30796 0.0112305 2.13257 0.0112305Z"
                        fill="white"
                      />
                      <path
                        d="M3.73293 5.1333H0.531656C0.379378 5.1333 0.255859 5.25676 0.255859 5.40916V15.7131C0.255859 15.8655 0.379378 15.9889 0.531656 15.9889H3.73287C3.88521 15.9889 4.00873 15.8654 4.00873 15.7131V5.40916C4.00879 5.2567 3.88527 5.1333 3.73293 5.1333Z"
                        fill="white"
                      />
                      <path
                        d="M11.9166 5.01172C10.7451 5.01172 9.7156 5.36845 9.08737 5.95015V5.40888C9.08737 5.25648 8.96386 5.13302 8.81152 5.13302H5.7407C5.58836 5.13302 5.46484 5.25648 5.46484 5.40888V15.7128C5.46484 15.8652 5.58836 15.9886 5.7407 15.9886H8.93891C9.09125 15.9886 9.21476 15.8651 9.21476 15.7128V10.615C9.21476 9.15229 9.4838 8.24569 10.8265 8.24569C12.1493 8.24729 12.2483 9.21946 12.2483 10.7024V15.7128C12.2483 15.8652 12.3718 15.9886 12.5242 15.9886H15.7239C15.8762 15.9886 15.9997 15.8651 15.9997 15.7128V10.0607C15.9996 7.70995 15.5355 5.01172 11.9166 5.01172Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_518_1983">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </li>
              <li className="me-4">
                <a href="#">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_518_1988)">
                      <path
                        d="M13.3423 1.3335H2.65832C1.56029 1.3335 0.666992 2.2268 0.666992 3.32482V14.0088C0.666992 15.1069 1.56029 16.0002 2.65832 16.0002H13.3423C14.4404 16.0002 15.3337 15.1069 15.3337 14.0088V3.32482C15.3337 2.2268 14.4404 1.3335 13.3423 1.3335ZM8.00033 12.2498C6.19167 12.2498 4.72025 10.7784 4.72025 8.96977C4.72025 7.16117 6.19172 5.6897 8.00033 5.6897C9.80893 5.6897 11.2804 7.16117 11.2804 8.96977C11.2804 10.7784 9.80898 12.2498 8.00033 12.2498ZM14.0919 6.54851H11.1284C10.3803 5.65293 9.25574 5.0822 8.00033 5.0822C6.74491 5.0822 5.62038 5.65298 4.87228 6.54851H1.90875V3.70059C1.90875 2.97672 2.4977 2.38783 3.22157 2.38783H12.7791C13.503 2.38783 14.0919 2.97672 14.0919 3.70059V6.54851H14.0919Z"
                        fill="white"
                      />
                      <path
                        d="M8.00026 6.86133C6.8376 6.86133 5.8916 7.80728 5.8916 8.96994C5.8916 10.1327 6.83755 11.0786 8.00026 11.0786C9.16298 11.0786 10.1089 10.1327 10.1089 8.96994C10.1089 7.80728 9.16293 6.86133 8.00026 6.86133Z"
                        fill="white"
                      />
                      <path
                        d="M12.4748 3.22949H11.2057C10.9157 3.22949 10.6797 3.46552 10.6797 3.75557V5.02464C10.6797 5.31469 10.9156 5.55067 11.2057 5.55067H12.4748C12.7648 5.55067 13.0008 5.31474 13.0008 5.02464V3.75557C13.0008 3.46547 12.7648 3.22949 12.4748 3.22949Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_518_1988">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-6">
            <div className="d-flex justify-content-sm-end justify-content-center ">
              <a href="#" className="me-2">
                Privacy policy
              </a>
              <a href="#">Terms & conditions</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
