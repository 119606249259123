import React, { useEffect, useState } from "react";

const ScrollIndicator = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const handleScroll = () => {
        const currentPosition = window.pageYOffset || document.documentElement.scrollTop;
        const maxScrollHeight = document.documentElement.scrollHeight - window.innerHeight;
        const percentage = (currentPosition / maxScrollHeight) * 100;
        setScrollPosition(percentage);
    
        // Check if the top part of the about section is in the viewport
        const aboutSection = document.getElementById('about-bg');
        if (aboutSection) {
          const rect = aboutSection.getBoundingClientRect();
          const isInViewport = rect.top >= 0 && rect.top <= window.innerHeight;
          setActiveSection(isInViewport ? 'about-bg' : '');
        }
      };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleButtonClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const containerStyles = {
    position: "fixed",
    bottom: "60px",
    right: "20px",
    height: "80px",
    padding: "10px",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
    zIndex: "999", // Ensure it's on top
  };

  const lineStyles = {
    position: "absolute",
    top: "50px", // Adjusted position
    right: "50%",
    width: "2px",
    height: `${scrollPosition}%`,
    background: activeSection === "about-bg" ? "white" : "#001715",
    transition: "height 0.4s",
    zIndex: "1", // Ensure it's behind the button
  };

  const buttonTextStyles = {
    width: "max-content",
    color: activeSection === "about-bg" ? "white" : "#001715",
    height: "30px",
    borderRadius: "50%",
    cursor: "pointer",
    background: "none",
    marginBottom: "6px",
    position: "absolute",
    top: "0",
    display: "flex",
    transition: "all 0.4s ease",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "rotate(90deg)",
    outline: "none",
    zIndex: "2",
  };

  return (
    <div style={containerStyles}>
      <button style={buttonTextStyles} onClick={handleButtonClick}>
        To Top
      </button>
      <div style={lineStyles}></div>
    </div>
  );
};

export default ScrollIndicator;
